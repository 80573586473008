import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isUserAgreement: false,
    showUserAgreement: false
  },
  mutations: {
    SET_USER_AGREEMENT: (state, data) => {
      state.isUserAgreement = data;
    }
  },
  actions: {},
  modules: {}
});
