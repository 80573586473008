
import { jsConfig, getOpenId } from "@/api";
import Vue from "vue";
export const urlToObj = function(str) {
  var obj = {};
  var arr1;
  var arr2;
  if (str) {
    arr1 = str.split("?");
  }
  if (arr1[1]) {
    arr2 = arr1[1].split("&");
  } else {
    return false;
  }
  for (var i = 0; i < arr2.length; i++) {
    var res = arr2[i].split("=");
    obj[res[0]] = res[1];
  }
  return obj;
};

export const formatFilterItem = function(arr) {
  if (!arr && !arr.length) return [];
  return arr.map(item => item.cateName);
};

//判断用户的打开方式
export const Device = function () {
  const { userAgent: UA } = navigator;
  const UA_L = UA.toLowerCase();
  const judgeDevice = {
    trident: UA.includes("Trident"), //IE内核
    presto: UA.includes("Presto"), //opera内核
    iPad: UA.includes("iPad"), //是否iPad
    iPhone: UA.includes("iPhone"), //是否为iPhone或者QQHD浏览器
    webKit: UA.includes("AppleWebKit"), //苹果、谷歌内核
    webApp: UA.indexOf("Safari") === -1, //是否web应用程序，没有头部与底部
    mobile: !!UA.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    android: UA.includes("Android") || UA.includes("Linux"), //android终端或uc浏览器
    gecko: UA.includes("Gecko") && UA.indexOf("KHTML") === -1, //火狐内核
    wechat: UA_L.toLowerCase().match(/MicroMessenger/i) == "micromessenger" // 微信
  };
  return judgeDevice;
}

// 获取openid
export const getOpenID = function (that, fn) {
  if (JSON.parse(localStorage.getItem('openidObj')).openid != "yyyy") {
    Vue.prototype.openidObj = JSON.parse(localStorage.getItem('openidObj'));
    fn(JSON.parse(localStorage.getItem('openidObj')))
    return
  }
  let code = ""
  if (that.$route.query.openid) {
    code = that.$route.query.openid
  } else {
    code = getNum()
  } 
  Vue.prototype.openidObj = { openid: code };
  localStorage.setItem('openidObj', JSON.stringify({ openid: code }))
  // that.$get("/api-voice/voice/index", { code: code }).then(response => {
    // 	// localStorage.setItem('user', JSON.stringify(response.result))
    fn({ openid: code })
  // })
}
function getNum (params) {
  let chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  let nums = ''
  for (let i = 0; i < 16; i++) {
    let id = parseInt(Math.random() * 61)
    nums += chars[id]
  }
  return nums
}

// userInfo相关设置
window.userInfoSet = {
  default (that) {
    // 设定用户来源
    if (!JSON.parse(localStorage.getItem("openidObj")).openid) {
      localStorage.setItem('openidObj', JSON.stringify({ "openid": getNum() }))
    }
  },
  uapp (that) {
    sessionStorage.setItem("source", 3)
    let user = JSON.parse(localStorage.getItem('openidObj'))
    window.deviceInfo = {
      deviceId: location.href.split('deviceId=')[1].split('&')[0],
      longitude: location.href.split('longitude=')[1].split('&')[0],
      latitude: location.href.split('latitude=')[1].split('&')[0],
      version: location.href.split('version=')[1].split('&')[0],
      //修改 app 交互逻辑
      isWKWeb: 2
    }
    if (location.href.indexOf("isWKWeb=") != -1) {
      window.deviceInfo.isWKWeb = parseInt(location.href.split('isWKWeb=')[1].split('&')[0])
    }
    console.log('location.href==============', location.href)
    console.log('isWKWeb==============', deviceInfo.isWKWeb)
    // 如果取不到user数据
    if (user || user.openid === null) {
      if (deviceInfo.deviceId) {
        user.openid = deviceInfo.deviceId
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        log('warn', 'openid', '获取不到设备信息')
        if (!localStorage.getItem('guid')) {
          let S4 = () => {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
          }
          let guid = () => {
            return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
          }
          localStorage.setItem('guid', guid())
        }
        user.openid = localStorage.getItem('guid')
        localStorage.setItem('user', JSON.stringify(user))
      }
    }
  },
  async wechat (that) {
    that.wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        sessionStorage.setItem("source", 2)
      } else {
        sessionStorage.setItem("source", 1)
      }
    })
      const response = await jsConfig(
        encodeURIComponent(location.href.split("#")[0])
      );

    // that.axios('api-voice/voice/jsConfig?url=' + encodeURIComponent(location.href.split("#")[0]), "").then(response => {
      var config = response.datas
      console.log('config',response)
      that._.unset(config, 'access_token')
      that._.set(config, 'jsApiList', [
        'getSetting',
        'checkJsApi',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'hideMenuItems',
        'showMenuItems',
        'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem',
        'translateVoice',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'onVoicePlayEnd',
        'pauseVoice',
        'stopVoice',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'closeWindow',
        'scanQRCode',
        'chooseWXPay',
        'openProductSpecificView',
        'addCard',
        'chooseCard',
        'openCard',
      ])
      that.wx.config(config)

      that.wx.ready(function () {
      });
      that.wx.error(function (err) {
        console.log(err, "失败");
      });
    // })
  },
}