import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import _ from "lodash";
import axios from "axios";
import wx from "./utils/wechat";
import Vconsole from "vconsole";
let vConsole = new Vconsole();
import audioConfig from "@/config";
import "./components";
import {
  Icon,
  Search,
  Button,
  Tab,
  Tabs,
  Grid,
  GridItem,
  Cell,
  Switch,
  Toast,
  DropdownMenu,
  DropdownItem,
  Dialog,
  ActionSheet,
  Checkbox
} from "vant";
import "vant/lib/index.css";
Vue.use(Icon);
Vue.use(Search);
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(GridItem);
Vue.use(Grid);
Vue.use(Cell);
Vue.use(Switch);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Checkbox);
Vue.use(vConsole);
Vue.config.productionTip = false;
Vue.prototype._ = _;
Vue.prototype.wx = wx;
Vue.prototype.axios = axios;
Vue.prototype.audioConfig = audioConfig;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
