<template>
  <div id="app">
    <keep-alive>
      <router-view
        :text="text"
        @showUserAgreement="showUserAgreement"
        :src="src"
        v-if="flag"
      />
    </keep-alive>
    <user-agreement ref="useragreement"></user-agreement>
    <iframe allow="autoplay" style="display:none"></iframe>
    <audio id="audio" name="media" autoplay src></audio>
  </div>
</template>

<script>
import {Device,getOpenID} from "./utils/helpers.js"
import Audio from "./utils/audio";
import Vue from "vue";
export default {
  name: "App",
  data() {
    return {
      src: "",
      text: "",
      openidObj: {
        openid: "yyyy"
      },
      flag: false
    };
  },
  created() {
    // console.log(wx)
    // wx.ready(() => {
    //     wx.authorize({
    //         scope: 'scope.record',
    //         success: () => {
    //             console.log('录音权限通过')
    //         },
    //         fail: () => {
    //             console.log('录音权限被拒绝')
    //         }
    //     })
    // })
},

  mounted() {
      // console.log(this.$route.query.openid)
      // const clientObject = Device();
    // if (this.$route.query.openid) {
    //     clientObject.miniprogram = true;
    // } else {
    //     clientObject.miniprogram = false;
    // } 
    // Vue.prototype.clientObject = clientObject;
    this.wxConfigse()//获取权限，以及设定用户来源，提交用户的来源
    this.setaudio()
    if (!localStorage.getItem('openidObj')) {
      localStorage.setItem("openidObj", JSON.stringify(this.openidObj));
    }
    if (!sessionStorage.getItem('pageData')) {
      sessionStorage.setItem("pageData", JSON.stringify(""));
      sessionStorage.setItem("searchMethod", 1);
      sessionStorage.setItem("clear", "");
      sessionStorage.setItem("scrollTop", 0);
    }
    var that=this
    getOpenID(this,function(openidObj){
      console.log(openidObj)
      that.openidObj=openidObj
      that.flag=true
    })
  },
  methods: {
    setaudio(){
      Vue.prototype.audio = new Audio();
      if (JSON.parse(localStorage.getItem("volumn")) == 0) {
        document.getElementById("audio").muted = true;
      } else {
        document.getElementById("audio").muted = false;
      }
      const url = this.audioConfig.welcome[
        Math.floor(Math.random() * this.audioConfig.welcome.length)
      ]["voice"];
      this.src = url;
      //获取voice的index
      let CurrentVoiceIndex = this.audioConfig.welcome.findIndex(
        item => item.voice == this.src
      );
      //获取voice相对应的文字
      this.text = this.audioConfig.welcome[CurrentVoiceIndex]["text"];
      console.log(this.text);
      document.addEventListener(
        "WeixinJSBridgeReady",
        () => {
          // this.audio.src(url);
        },
        false
      );
    },
    // 触发的方法
    showUserAgreement() {
      this.$refs.useragreement.show = true;
    },
    wxConfigse(){
      const clientObject = Device();
      Vue.prototype.clientObject = clientObject;
      if(clientObject.wechat){//小程序或微信浏览器
        window.userInfoSet.wechat(this)
      } else if (clientObject.uapp) {//安卓或ios优衣库app
        window.userInfoSet.uapp(this)
      } else{
        window.userInfoSet.wechat(this)
      }

      var that=this
      var isPageHide = false;   
      window.addEventListener('pageshow', function () {   
        if (isPageHide) {   
          window.location.reload();   
        }   
      });   
      window.addEventListener('pagehide', function () {   
        isPageHide = true;   
      }); 
      window.addEventListener("popstate", function(e) { 
      }, false);
    }
  }
};
</script>
<style>
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
  -webkit-touch-callout: none;
}
input,
textarea {
  -webkit-user-select: auto;
  user-select: auto;
}
body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  overflow: hidden;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}
*:not(i) {
  font-family: "PingFang SC", "Microsoft Yahei", "Apple Legacy Chevron",
    "SF Pro SC", "HanHei SC", "SF Pro Text", "Myriad Set Pro", "SF Pro Icons",
    "Arial", sans-serif;
}
#__vconsole {
  display: none;
}
</style>
