class Audio {
  constructor() {
    this.audio = document.getElementById("audio");
    this.audio.volume = window.localStorage.getItem("volumn") || 1;
  }
  src(src) {
    this.audio.src = src;
    this.audio.load();
    this.audio.play();
  }
  stop() {
    this.audio.pause();
  }
  play() {
    console.log(1);
    this.audio.play();
  }
  volume(num) {
    this.audio.volume = num;
    if (num <= 0) {
      this.stop();
    }
  }
}

export default Audio;
