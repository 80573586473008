<template>
  <div class="user-agreement" v-show="isShow">
    <van-action-sheet
      v-model="show"
      class="info"
      :close-on-click-overlay="false"
    >
      <h4>智能语音导购员:</h4>
      <h5>申请获取您的用户登录信息和语音授权信息</h5>
      <div class="checkbox-box">
        <van-checkbox
          v-model="checked"
          class="vant-checkbox"
          shape="square"
        ></van-checkbox>
        <span style="padding-left:5px"
          >已阅读相关<a
            rel="noopener noreferrer"
            target="_blank"
            href="https://static.zara.cn/static/pdfs/CN/terms-and-conditions/terms-and-conditions-zh_CN-20190805.pdf"
          >
            购买条款 </a
          >及<a
            rel="noopener noreferrer"
            target="_blank"
            href="https://static.zara.cn/static/pdfs/CN/privacy-policy/privacy-policy-zh_CN-20181231.pdf"
          >
            隐私声明</a
          ></span
        >
      </div>
      <div class="info-button">
        <van-button plain type="primary" @click="_handleClickNo"
          >不同意</van-button
        >
        <van-button type="primary" @click="_handleClickSaveUser"
          >同意</van-button
        >
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { saveAgreement, isAgreement } from "@/api";
import axios from "axios";
export default {
  name: "UserAgreement",
  data() {
    return {
      checked: false,
      show: false
    };
  },
  computed: {
    isUserAgreement: function() {
      return this.$store.state.isUserAgreement;
    },
    isShow: function() {
      return this.isUserAgreement && this.show;
    },
    openidObj: function() {
      return JSON.parse(localStorage.getItem("openidObj") || {});
    }
  },
  watch: {
    isUserAgreement(newVal, oldVal) {
      console.log(newVal, oldVal, "isUserAgreement");
    },
    show(newVal, oldVal) {
      console.log(newVal, oldVal, "show");
    }
  },
  mounted() {
    this._getUserAgreement();
  },
  methods: {
    // 查询用户协议
    async _getUserAgreement() {
      // let json = '{"datas":2,"resp_code":0,"resp_msg":"","root_tag":null,"version":0}';
      // const res = JSON.parse(json)
      
      axios.post('https://yx.applesay.tech/api-user/agreement/agreementState', {   
        pcId: "",
        channelCode: "oqyaOkKVZSbuoph0",
        version: "1",
        openId: this.openidObj.openid })
        .then(res => {

          var { datas } = res.data;
          console.log(res)
      if (datas === 1) {
        // console.log("herere")
        this.show = true;
        this.$store.commit("SET_USER_AGREEMENT", true);
      }
        })
        .catch(error => {
          // 处理错误
          console.error("There was an error!", error);
        });

      // const res = await isAgreement({ openId: this.openidObj.openid });
      // var { datas } = res;
      //     console.log(res)
      // if (datas === 1) {
      //   console.log("herere")
      //   this.show = true;
      //   this.$store.commit("SET_USER_AGREEMENT", true);
      // }
    },
    // 不同意用户协议
    _handleClickNo() {
      this.checked = false;
      this.show = false;
    },
    //  同意用户协议
    async _handleClickSaveUser() {
      if (this.checked) {
        axios.post('https://yx.applesay.tech/api-user/agreement/saveAgreementRecord', {   
          lngId: "0",
            channelCode: "oqyaOkKVZSbuoph0",
            version: "1",
            state: "1",
        openId: this.openidObj.openid })
        .then(res => {
        })
        .catch(error => {
          // 处理错误
          console.error("There was an error!", error);
        });

        // await saveAgreement({ openId: this.openidObj.openid });
        this.$store.commit("SET_USER_AGREEMENT", false);
        
        this.wx.startRecord({
      success:res=>{
        console.log(res)
        console.log("开始授权")
        this.wx.stopRecord();
      },
      cancel:res=>{
        console.log(res)
        console.log("用户取消授权")
      },
      fail:res=>{
        console.log(res)
      }
    })
      } else {
        this.$toast({
          message: "请先同意协议~",
          icon: "fail",
          position: "center"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-agreement {
  position: fixed;
  bottom: 0vh;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 11;
  h5 {
    font-size: 4vw;
  }
  .info {
    padding: 3vh 6vw;
    box-sizing: border-box;
    .checkbox-box {
      display: flex;
      align-items: center;
      a {
        color: #f26989;
      }
      .vant-checkbox {
        display: inline-block;
      }
    }
    .info-button {
      margin: 2vh 0;
      display: flex;
      justify-content: space-between;
      button {
        width: 40vw;
        height: 4vh;
      }
    }
  }
}
</style>
