<template>
  <div class="about">
    <navBtn />
    <van-cell title="用户反馈" is-link @click="_handleClickYHFK" />
    <van-cell title="用户教程" is-link @click="_handleClickYHJC" />
    <van-cell title="静音" is-link>
      <template #icon>
        <van-switch
          v-model="checked"
          size="20px"
          style="margin-right: 10px"
          @change="_handleClickMute"
        />
      </template>
    </van-cell>
  </div>
</template>
<script>
import navBtn from "@/components/navBtn.vue";
import { saveBehavior } from "@/api";
export default {
  components: {
    navBtn
  },
  name: "about",
  data() {
    return {
      isVoicePlay: true,
      isOpenVoicePlay: true,
      checked: false
    };
  },
  mounted() {
    // this.getLang()
    //获取缓存里的音量
    let currentVolumnState = localStorage.getItem("volumn");
    if (currentVolumnState == 0) {
      this.checked = true;
      document.getElementById("audio").muted = true;
    } else {
      this.checked = false;
      document.getElementById("audio").muted = false;
    }
  },
  methods: {
    _handleClickYHFK() {
      this.$router.push({ path: "userFeedback" });
    },
    _handleClickYHJC() {
      this.$router.push({ path: "userCourse" });
    },
    _handleClickMute(val) {
      saveBehavior({
        // 保存用户行为
        handleHtml: "帮助页",
        handleType: 402
      });
      if (val) {
        document.getElementById("audio").muted = true;
        localStorage.setItem("volumn", 0);
        this.audio.volume(0);

        console.log(this.audio);
      } else {
        document.getElementById("audio").muted = false;
        localStorage.setItem("volumn", 0.5);
        this.audio.volume(0.5);
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
