export default {
  welcome: [
    {
      voice:
        "https://yx-voice.oss-cn-shanghai.aliyuncs.com/bd8ce55bdc7b461fb9495306b30c2d54.mp3",
      text: "您可以长按我，对我说想看哪一种款式的商品"
    },
    {
      voice:
        "https://yx-voice.oss-cn-shanghai.aliyuncs.com/8b334263ffa340bca189398f33b4fe61.mp3",
      text: "你想挑选适合什么场合的商品呢"
    },
    {
      voice:
        "https://yx-voice.oss-cn-shanghai.aliyuncs.com/2768f32a54c44e7899d83c75c0fe9301.mp3",
      text: "长按我说优惠商品可以进行筛选哦"
    },
    {
      voice:
        "https://yx-voice.oss-cn-shanghai.aliyuncs.com/a6f5e2e8e0aa4042a007ee7b1bd631af.mp3",
      text: "你好呀，长按我说新品就能查看最新商品哦"
    }
  ]
};
