import Vue from "vue";
import VueRouter from "vue-router";
import Good from "@/views/good.vue";
import About from "@/views/about.vue";
import userFeedback from "@/views/userFeedback.vue";
import userCourse from "@/views/userCourse.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Good",
    component: Good
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/userFeedback",
    name: "userFeedback",
    component: userFeedback
  },
  {
    path: "/userCourse",
    name: "userCourse",
    component: userCourse
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router;
