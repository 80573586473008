<template>
  <div class="user-feedback m-ov-a ZARA">
    <navBtn />
    <div style="margin: 0 16px 16px;-webkit-user-select:text !important;">
      <h3 class="title">用户意见反馈</h3>
      <van-dropdown-menu>
        <van-dropdown-item v-model="value.feedbackType" :options="option" />
      </van-dropdown-menu>
      <textarea
        class="m-d-b m-pa-2"
        maxlength="300"
        placeholder="请填写10个字以上的问题描述"
        rows="7"
        v-model="value.feedback"
        style="user-select:text !important;"
      ></textarea>
      <input
        class="m-d-b "
        placeholder="您的联系方式（手机号、QQ号或电子邮箱）"
        type="text"
        v-model="value.contact"
      />
      <button @click="submit" class="submit">提交</button>
    </div>
  </div>
</template>

<script>
import navBtn from "@/components/navBtn.vue";
import { saveBehavior, userFeedback } from "@/api";
export default {
  name: "userFeedback",
  components: {
    navBtn
  },
  data() {
    return {
      selectValue: 1,
      option: [
        { text: "意见反馈", value: 1 },
        { text: "用户数据删除", value: 2 },
        { text: "投诉反馈", value: 3 },
        { text: "其他反馈", value: 4 }
      ],
      value: {
        feedback: "",
        contact: "",
        feedbackType: 1
      },
      openSimple: false
    };
  },
  methods: {
    submit() {
      // 检查完整性
      if (!this.value.feedbackType) {
        return this.$toast({
          message: "请选择反馈类型",
          icon: "fail",
          position: "top"
        });
      }
      if (this.value.feedback.length < 1) {
        return this.$toast({
          message: "反馈内容未填写",
          icon: "fail",
          position: "top"
        });
      } else if (this.value.feedback.length < 10) {
        return this.$toast({
          message: "请至少输入10个字符的反馈内容",
          icon: "fail",
          position: "top"
        });
      } else if (this.value.feedback.length > 200) {
        return this.$toast({
          message: "最多可输入200个字符",
          icon: "fail",
          position: "top"
        });
      }
      if (this.value.contact.length < 1) {
        return this.$toast({
          message: "联系方式未填写",
          icon: "fail",
          position: "top"
        });
      } else if (this.value.contact.length > 32) {
        return this.$toast({
          message: "联系方式最多可输入32个英文或数字",
          icon: "fail",
          position: "top"
        });
      }
      saveBehavior({
        // 保存用户行为
        handleHtml: "帮助页",
        handleType: 403
      });
      // 发起请求
      userFeedback({
        feedback: this.value.feedback,
        contacts: this.value.contact,
        feedbackType: this.value.feedbackType
      }).then(() => {
        // 清空输入框
        this.value.feedback = "";
        this.value.contactNum = "";
        this.value.contact = "";
        this.value.feedbackType = 1;
        // 打开提示框
        return this.$dialog({
          title: "提交成功",
          message: "感谢您的反馈与建议"
        });
      });
    }
  }
};
</script>

<style lang="scss">
.user-feedback {
  background-color: #f5f5f5;
  min-height: 100vh;
  .title {
    text-align: center;
    margin: 0;
    padding: 20px 0;
  }
  .submit {
    font-size: 18px;
    width: 100%;
    background: linear-gradient(#666, #000);
    color: #fff;
    border-radius: 1vw;
    border: none;
    padding: 8px 8px;
    margin-top: 16px;
  }
  textarea {
    width: 100%;
    border-radius: 1vw;
    border: 1px solid #e0e0e0;
    padding: 8px 0;
    display: block;
    overflow: auto;
    font: inherit;
    margin: 0;
    resize: none;
    -webkit-user-select: text !important;
  }
  input {
    width: 100%;
    border-radius: 1vw;
    border: 1px solid #e0e0e0;
    padding: 8px 0;
    display: block;
    margin-top: 16px;
  }
  button {
    font-size: 18px;
    width: 100%;
    background: linear-gradient(#fc9f5b, #ff542b);
    color: white;
    border-radius: 1vw;
    border: none;
  }
}
.ZARA.user-feedback {
  button {
    background: linear-gradient(#666, #000);
  }
}
</style>
