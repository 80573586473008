<template>
  <div class="user-course m-ov-a">
    <h3 style="text-align:center;">用户教程</h3>
    <navBtn />
    <div class="scrollBetter">
      <img alt src="@/assets/zara.jpg" width="100%" @click="imgSaleStart()" />
    </div>
  </div>
</template>

<script>
import navBtn from "@/components/navBtn.vue";
import { saveBehavior } from "@/api";
export default {
  components: {
    navBtn
  },
  data() {
    return {};
  },
  methods: {
    pageLoad() {},
    // 图片放大
    imgSaleStart() {
      // wx.previewImage({
      //   current: "https://app.applesay.cn/service/public/zara.jpg",
      //   urls: ["https://app.applesay.cn/service/public/zara.jpg"]
      // });
    }
  },
  mounted() {
    saveBehavior({
      // 保存用户行为
      handleHtml: "帮助页",
      handleType: 401
    }),
      this.pageLoad();
  }
};
</script>

<style lang="scss">
.scrollBetter {
  height: 100vh;
  overflow-y: scroll;
}
</style>
