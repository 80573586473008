import axios from "axios";

// create an axios instance
const service = axios.create({
  // baseURL: ENV.hosturl, // url = base url + request url
  baseURL: process.env.VUE_APP_API
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: process.env.VUE_APP_TIMEOUT * 10000 // request timeout
});

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.resp_code !== 0) {
      console.log("%c 接口返回日志", "font-size:18px;color:red", response);
      return Promise.reject(new Error(res.resp_msg || "Error"));
    } else {
      return res;
    }
  },
  error => {
    console.dir(error);
    return Promise.reject(error);
  }
);

export default service;
