import request from "@/utils/request";

export function textSearch(data) {
    // 文本搜索等
    return request({
        url: "api-product/zara/h5/textProduct",
        method: "post",
        data
    });
}

export function filterTab() {
    // 获取筛选项
    return request({
        url: "api-tag/iqcategory/menu?channel=oqyaOkKVZSbuoph0",
        method: "get"
    });
}

export function getHot() {
    // 获取热词
    return request({
        url: `api-tag/searchTag/hot/oqyaOkKVZSbuoph0/0`,
        method: "get"
    });
}

export function getsColors() {
    // 获取热词
    return request({
        url: `api-product/zara/h5/getZaraColor`,
        method: "get"
    });
}

export function menu() {
    // 获取筛选项
    return request({
        url: "api-tag/iqcategory/menu?channel=oqyaOkKVZSbuoph0",
        method: "get"
    });
}

export function sendVoice(data) {
    // 语音接口
    return request({
        url: "api-product/zara/h5/searchProduct",
        method: "post",
        data
    });
}

export function textTip(name) {
    // 文字联想
    return request({
        url: `api-product/productTag/prompTag?name=${name}&lng=0&channel=oqyaOkKVZSbuoph0`,
        method: "get"
    });
}

export function jsConfig(url) {
    //  获取 jsConfig
    return request({
        url: `api-voice/voice/jsConfig?url=${url}`,
        method: "get"
    });
}

export function getOpenId(code) {
    //  获取 openId
    return request({
        url: `/api-voice/voice/index?code=${code}`,
        method: "get"
    });
}

export function getAuthorize() {
    //  获取 code
    return request({
        url: "/api-voice/voice/getAuthorize?project=",
        method: "get"
    });
}

export function wechatTranslate(serverId) {
    // 发送录音 id
    return request({
        url: `api-voice/wechatTranslate?serverId=${serverId}`,
        method: "post"
    });
}

export function saveBehavior(data) {
    // request({
    //   url: "/api-product/zaraburiedpoint/save",
    //   method: "post",
    //   data: {
    //     code:data.handleType,
    //     page:data.handleHtml,
    //     lng: 0,
    //     channel: "oqyaOkKVZSbuoph0",
    //     source: localStorage.getItem("clientType") || "1",
    //     uid: JSON.parse(localStorage.getItem("openidObj") || {}).openid || "yyyy",
    //     ...data}
    //   })
    //   if(data.handleType===10001){
    //     return
    //   }
    // 保存用户行为
    return request({
        url: "/api-product/product/saveBehavior",
        method: "post",
        data: {
            lng: 0,
            channel: "oqyaOkKVZSbuoph0",
            source: localStorage.getItem("clientType") || "1",
            uid: JSON.parse(localStorage.getItem("openidObj") || {}).openid || "yyyy",
            ...data
        }
    });
}

export function scanCode(code) {
    // 扫码
    return request({
        url: `api-product/zara/scanList?pageNum=1&pageSize=10&talk=${code}&lng=0&source=${localStorage.getItem(
            "clientType"
        ) || 1}`,
        method: "get"
    });
}

export function modelDetail(id) {
    // 搭配详情
    return request({
        url: `api-product/modeldetail1/modelDetail?modelId=${id}&lng=0`,
        method: "get"
    });
}

export function getSpecialData(params) {
    // 特辑查询
    return request({
        url: `api-product/spider`,
        method: "get",
        params
    });
}

export function getSpecialDetilsData(id) {
    // 特辑id查询 详情
    return request({
        url: `api-product/spider/${id}`,
        method: "get"
    });
}

export function userFeedback(options) {
    // 用户反馈
    return request({
        url: "/api-tag/userFeedback",
        method: "post",
        data: {
            userId:
                JSON.parse(localStorage.getItem("openidObj") || {}).openid || "yyyy",
            channelCode: "oqyaOkKVZSbuoph0",
            ...options
        }
    });
}

// 保存用户协议
export function saveAgreement(data) {
    return request({
        url: "/api-user/agreement/saveAgreementRecord",
        method: "POST",
        data: {
            lngId: "0",
            channelCode: "oqyaOkKVZSbuoph0",
            version: "1",
            state: "1",
            ...data
        }
    });
}

// 查询用户协议
export function isAgreement(data) {
    return request({
        url: "/api-user/agreement/agreementState",
        method: "POST",
        data: {
            pcId: "",
            channelCode: "oqyaOkKVZSbuoph0",
            version: "1",
            ...data
        }
    });
}

export function scoreDetail(data) {
    return request({
        url: `api-product/product/getScoreDetail?productId=` + data.productId + `&tag=` + data.tag + `&content=` + data.content,
        method: "get"
    });
}

export function showTag(data) {
    return request({
        url: `api-product/product/showTag?productId=` + data.productId + `&channel=` + data.channel,
        method: "get"
    });
}